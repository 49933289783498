import { IQueryDefaultParams } from 'common/globalTypes/interfaces/apiRequestModel';
import { LanguagesType } from 'common/globalTypes/types/common';

export enum EndPointTypes {
  applicationsPrefix = 'applications',
  reviewCounts = 'helpful-unhelpful-reviews',
  searchApplicationPrefix = 'search',
  byAlias = 'by-alias',
  install = 'install',
  applicationReviews = 'reviews',
  addApplicationReview = 'application-reviews',
  editApplicationReview = 'application-reviews',
  deleteApplicationReview = 'application-reviews',
  helpfulOrUnhelpfulApplicationReview = 'application-reviews',
  singleApplicationReview = 'application-reviews',
  applicationRateCount = 'rate-counts',
  buyApplication = 'api/applications/purchase',
  currentPlan = 'current-plan',
  api = 'api',
  permissions = 'permissions',
  applicationMetaData = 'meta-details'
}

export interface IApplicationsRequestModel extends IQueryDefaultParams {
  categoryAlias?: string;
  lang?: LanguagesType;
}

export interface ISearchApplicationsParams extends IQueryDefaultParams {}
